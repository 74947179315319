import Link from 'next/link';

import { StyledLink } from './withLinkedImages.styled';
import { ImageProperties } from './withLinkedImages.types';

export const withLinkedImages = <P extends ImageProperties>(
    Component: React.ComponentType<P>,
) => {
    const ImageComponent = (properties: P) => {
        const { isImageClickable, href, altText } = properties;

        if (isImageClickable && href) {
            return (
                <Link href={href} passHref>
                    <StyledLink tabIndex={-1} aria-label={altText}>
                        <Component {...properties} />
                    </StyledLink>
                </Link>
            );
        }
        return <Component {...properties} />;
    };

    const displayName = Component.displayName || Component.name;
    ImageComponent.displayName = `withTheme(${displayName})`;

    return ImageComponent;
};
