import { useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useState, ChangeEvent, useEffect } from 'react';

import { theme, palette } from '../../theme';
import { Icon } from '../Icon';
import { StyledMuiTabs, StyledTab } from './Tabs.styled';
import { TabItem, TabsProperties } from './Tabs.types';
import { dispatchEvent, EventKey, ProductViewEvent } from '@tgg/services';

const daysOfWeekFull = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

/**
 * Use the `Tabs` component when you want to use tabbed panels.
 */
export function Tabs({
    isDaysOfTheWeek = false,
    isCustomDays = false,
    customDays,
    items,
    daysOfTheWeekContent,
    tabGroupAriaLabel,
    uniqueId,
    gymName,
    defaultSelectedIndex = 0,
    onChange = () => {},
}: TabsProperties) {
    const isIconGivenForEveryItem =
        !isDaysOfTheWeek && items?.every(item => item.iconName);
    const isIconGivenForSomeItems =
        !isDaysOfTheWeek && items?.some(item => item.iconName);
    const numberOfTabs = isDaysOfTheWeek ? 7 : (items && items?.length) || 2;

    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const router = useRouter();

    const daysOfWeek =
        isDaysOfTheWeek && !isCustomDays
            ? daysOfWeekFull.map((day: string) => {
                  return {
                      label: isDesktop ? day : day.slice(0, 3),
                  } as TabItem;
              })
            : customDays?.map((day: string) => {
                  return {
                      label: day,
                  } as TabItem;
              });
    const tabItems = isDaysOfTheWeek ? daysOfWeek : items;
    const defaultSelectedTab =
        router.query.changeDate && tabItems
            ? tabItems.length - 1
            : defaultSelectedIndex;

    const [selectedIndex, setSelectedIndex] = useState(defaultSelectedTab);

    useEffect(() => {
        setSelectedIndex(defaultSelectedTab);
    }, [defaultSelectedTab]);

    const handleChange = (
        event: ChangeEvent<Record<string, any>>,
        newValue: number,
    ) => {
        const membershipTypesPanel = tabItems && gymName;

        if (membershipTypesPanel && event.type === 'click') {
            const selectedProductType = tabItems[selectedIndex];

            const selectedProductOptions = selectedProductType.products;

            const listOfProducts = selectedProductOptions?.map(product => {
                const { description, expectedPaymentFrequency, price } =
                    product;
                return {
                    name: description,
                    price: price.value,
                    brand: gymName,
                    category: expectedPaymentFrequency,
                    variant: 'Create',
                    quantity: 1,
                };
            });

            if (listOfProducts) {
                dispatchEvent<ProductViewEvent>(EventKey.PRODUCT_DETAIL, {
                    event: EventKey.PRODUCT_DETAIL,
                    ecommerce: {
                        detail: {
                            products: listOfProducts,
                        },
                    },
                });
            }
        }
        onChange(newValue);
        setSelectedIndex(newValue);
    };

    if (!isDaysOfTheWeek && (!items || items.length === 0)) {
        return null;
    }

    if (items && (items.length === 0 || items.length > 5)) {
        return null;
    }

    if (isIconGivenForSomeItems && !isIconGivenForEveryItem) {
        return null;
    }

    if (isDaysOfTheWeek && !daysOfTheWeekContent) {
        return null;
    }

    return (
        <>
            <StyledMuiTabs
                value={selectedIndex}
                onChange={handleChange}
                selectionFollowsFocus
                variant="fullWidth"
                aria-label={tabGroupAriaLabel}
                $isIconGivenForEveryItem={isIconGivenForEveryItem}
            >
                {tabItems &&
                    tabItems?.map((item, index) => {
                        const {
                            label,
                            iconName,
                            onPaymentTabClick,
                            content,
                            ...analyticsItems
                        } = item;

                        return (
                            <StyledTab
                                label={label}
                                id={`tab-${uniqueId}-${index}`}
                                aria-controls={`tabpanel-${uniqueId}-${index}`}
                                disableFocusRipple
                                onClick={onPaymentTabClick}
                                disableRipple
                                key={`tab-${uniqueId}-${label}`}
                                {...analyticsItems}
                                $isIconGivenForEveryItem={
                                    isIconGivenForEveryItem
                                }
                                $numberOfTabs={numberOfTabs}
                                $customStyles={isCustomDays}
                                icon={
                                    iconName && (
                                        <Icon
                                            name={iconName}
                                            size={isDesktop ? 26 : 22}
                                            color={palette.primary.main}
                                        />
                                    )
                                }
                            />
                        );
                    })}
            </StyledMuiTabs>
            {
                //  below are the panels for each of the Tabs above.
                isDaysOfTheWeek && daysOfTheWeekContent ? (
                    <>
                        {daysOfTheWeekContent.map((dayContent, dayIndex) => {
                            return (
                                <div
                                    role="tabpanel"
                                    tabIndex={0}
                                    hidden={selectedIndex !== dayIndex}
                                    id={`tabpanel-${uniqueId}-${dayIndex}`}
                                    aria-labelledby={`tab-${uniqueId}-${dayIndex}`}
                                    key={`${daysOfWeekFull[dayIndex]}`}
                                >
                                    {dayContent}
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <> {items && items[selectedIndex].content}</>
                )
            }
        </>
    );
}

export default Tabs;
