import styled from 'styled-components';

import { Theme, palette } from '../../theme';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';
import { Icon } from '../Icon';
import { StyledIconProperties } from '../PartialCollapsible/PartialCollapsible.types';

export const StyledContainer = styled.div`
    max-width: 85rem;
    margin: 2rem 0;
    padding: 2rem 2.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 3rem 0 2rem;
        padding: 3rem 4rem;
    }
    border: 0.3rem solid ${palette.grey[50]};
    border-radius: 1.5rem;
`;

export const StyledHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledHeading = styled(Heading)``;

export const StyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
    background: none;
    border: 0.1rem solid ${palette.primary.main};
    border-radius: 50%;
    cursor: pointer;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 3.5rem;
        height: 3.5rem;
    }
`;

export const StyledChevronIcon = styled(Icon)<StyledIconProperties>`
    transform: ${({ $rotated }) =>
        $rotated ? 'rotate(-90deg);' : 'rotate(90deg)'};
    cursor: pointer;
    transition: all 0.3s;
`;

export const StyledTimeParagraph = styled.p`
    margin: 0.5rem 0 2rem;
`;

export const StyledDot = styled.span`
    color: ${palette.primary.main};
`;

export const StyledTrainerParagraph = styled.p`
    margin: 0 0 0.5rem;
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin-bottom: 2rem;
`;

export const StyledAvailabilityParagraph = styled.p`
    margin: 0;
`;

export const StyledDescriptionParagraph = styled.p`
    margin: 2rem 0;
`;

export const StyledSecondAvailabilityParagraph = styled.p`
    margin: 0;
`;
