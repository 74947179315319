import { Tabs as MuiTabs, Tab } from '@material-ui/core';
import styled from 'styled-components';

import { Theme, palette } from '../../theme';

export const StyledMuiTabs = styled(MuiTabs)<{
    $isIconGivenForEveryItem: boolean | undefined;
}>`
    min-height: ${({ $isIconGivenForEveryItem }) =>
        $isIconGivenForEveryItem ? '6.5rem;' : '3.8rem;'};

    & .MuiTabs-indicator {
        height: 0.5rem;
        background-color: ${palette.primary.main};
        border-radius: 0.25rem;
    }

    color: ${palette.common.blue};

    & .Mui-selected {
        color: ${palette.common.blue};
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        min-height: ${({ $isIconGivenForEveryItem }) =>
            $isIconGivenForEveryItem ? '7.4rem;' : '4.3rem'};
    }
`;

export const StyledTab = styled(Tab)<{
    $isIconGivenForEveryItem: boolean | undefined;
    $numberOfTabs: number;
    $customStyles: boolean | undefined;
}>`
    min-width: ${({ $customStyles }) => $customStyles && '4.3rem'};
    height: ${({ $isIconGivenForEveryItem }) =>
        $isIconGivenForEveryItem ? '3.8rem;' : '3.1rem'};
    font-weight: normal;
    font-size: 1.6rem;
    font-family: thegymgroupsans-Headline, sans-serif;
    text-transform: lowercase;
    box-shadow: ${`inset 0 -0.3rem 0 0 ${palette.grey[200]};`};
    opacity: 1;

    span {
        width: ${({ $customStyles }) => $customStyles && '2.9rem'};
    }

    &:focus-visible {
        border: 1px solid ${palette.primary.main};
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        height: ${({ $isIconGivenForEveryItem }) =>
            $isIconGivenForEveryItem ? '8.6rem;' : '3.8rem'};
        font-size: 1.5rem;

        span {
            min-width: ${({ $customStyles }) => $customStyles && '8.1rem'};
        }
    }
`;
