import { Media } from './media.types';

export enum objectPositionDevices {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

export enum objectPositionVariant {
    TOP = 'top',
    TOP_TO_CENTER = 'top to center',
    CENTER = 'center',
    CENTER_TO_BOTTOM = 'center to bottom',
    BOTTOM = 'bottom',
    LEFT = 'left',
    LEFT_TO_CENTER = 'left to center',
    CENTER_TO_RIGHT = 'center to right',
    RIGHT = 'right',
}

export type objectPosition = {
    [key in objectPositionDevices]?: {
        yCoordinate?: objectPositionVariant;
        xCoordinate?: objectPositionVariant;
    };
};

export interface ImageStandaloneProperties {
    /**
     * The ID attribute for the component.
     */
    id?: string;

    /**
     * Class name to apply styles to the root element.
     */
    className?: string;

    /**
     * Image to render as selected in Amplience or from a URL.
     */
    image: Media | string;

    /**
     * Description text for accessibility.
     */
    altText?: string;

    /**
     * Maximum image width.
     */
    width?: number | 'auto';

    /**
     * Maximum image height.
     */
    height?: number | 'auto';

    /**
     * The maximum image height on a mobile device.
     *
     * This is only used when `prioritiseImageLoading` is true.
     */
    maximumMobileImageHeight?: number;

    /**
     * Should the image be lazy loaded?
     *
     * If `prioritiseImageLoading` is true, this will be ignored.
     */
    lazy?: boolean;

    /**
     * Should the image be loaded as soon as the HTML loads?
     * If true, the `src` attribute will be set to the `image` property on the server.
     * This will result in the point of interest being less accurate will result in a lower largest contentful paint value.
     */
    prioritiseImageLoading?: boolean;

    /**
     * Specifies the alignment of the image
     */
    objectPosition?: objectPosition;

    /**
     * Override picture sizes for each breakpoint supported.
     */
    overrideSizes?: {
        default: Record<string, number>;
        smallMobile: Record<string, number>;
        mobile: Record<string, number>;
        desktop: Record<string, number>;
        largeDesktop: Record<string, number>;
    };

    /**
     * Defines whether the image should be rendered inside an anchor <a>.
     */
    isImageClickable?: boolean;

    /**
     * If given and if isImageClickable=true will have rendered inside <a>.
     */
    href?: string;
}
